<template>
  <div class="colo-order page">
    <div v-if="!configurator.length" class="colo-order__loading">
      <base-loader />
    </div>
    <div class="colo-order__content">
      <!--      <transition name="fade" mode="out-in">-->
      <page-block v-if="configurator.length" :title="$t('additional')" size="bigger">
        <div class="l-flex-gutter-20">
          <div class="l-col-12_ml-8">
            <main-card class="colo-order__card">
              <order-config
                :key="current.id"
                :configurator="current"
                class="colo-order__config"
                :period="period"
                @init="updateConfig"
                @change="updateConfig"
                @destroy="resetConfig"
              />
              <transition name="fade" mode="out-in">
                <page-block v-if="costDetails" :title="$t('summary')" size="bigger">
                  <order-summary :details="costDetails" />
                </page-block>
              </transition>
              <template #footer>
                <div class="colo-order__footer">
                  <div class="colo-order__price">
                    <span class="colo-order__sum bigger-heading">
                      {{ $n(cost, 'currency') }}
                    </span>
                    <span class="colo-order__price-text">
                      {{ $t('payfor') }}
                    </span>
                    <base-select
                      :value="periodValue"
                      :options="priceByPeriod"
                      :searchable="false"
                      :allow-empty="false"
                      label="label"
                      :show-labels="false"
                      class="colo-order__period"
                      @input="onPeriodChange"
                    />
                  </div>
                  <div class="colo-order__actions">
                    <base-button
                      :loading="isSendingToPay"
                      :disabled="isCalculating || isSendingToBasket"
                      class="colo-order__btn"
                      @click="sendToPay()"
                    >
                      {{ `${$t('pay')}  ${$n(cost, 'currency')}` }}
                    </base-button>
                    <base-button
                      :loading="isSendingToBasket"
                      :disabled="isCalculating || isSendingToPay"
                      theme="outlined"
                      class="colo-order__btn"
                      @click="sendToBasket()"
                    >
                      {{ $t('order') }}
                    </base-button>
                  </div>
                </div>
              </template>
            </main-card>
          </div>
        </div>
      </page-block>
      <!--      </transition>-->
    </div>
  </div>
</template>

<script>
import OrderConfig from '@/components/Configurator/OrderConfig';
import OrderSummary from '@/components/Configurator/OrderSummary';
import MainCard from '@/components/MainCard/MainCard';
import BaseSelect from '@/components/Select/BaseSelect';
import { debounce } from 'lodash';
import showErrorModal from '@/mixins/showErrorModal';
import wizard from '@/mixins/billmgr/wizard';
import handleRedirect from '@/mixins/billing/handleRedirect';
import updateBalance from '@/mixins/billing/updateBalance';
import { BillMgrPeriods } from '@/models/BillMgr/periods';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
import gtmMixin from '@/mixins/gtmMixin';
import qs from 'qs';
export default {
  name: 'ColoOrderDetails',
  components: { OrderConfig, OrderSummary, MainCard, BaseSelect },
  mixins: [showErrorModal, wizard, handleRedirect, updateBalance, gtmMixin],
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      configData: null,
      period: 1,
      cost: 0,
      costDetails: null,
      isCalculating: false,
      isSendingToBasket: false,
      isSendingToPay: false,
      startFunc: 'payment.add',
      startParams: {},
      configuratorClass: PaymentMethodConfigurator,
    };
  },
  computed: {
    list() {
      return this.$store.state.moduleColo.moduleColoOrder.list;
    },
    loading() {
      return this.$store.state.moduleColo.moduleColoOrder.loading;
    },
    current() {
      return this.$store.getters['moduleColo/moduleColoOrder/current'] || this.currentItem;
    },
    configurator() {
      if (!this.current) return [];
      return this.current.configurator || [];
    },
    configTextFields() {
      return this.configurator.filter(addon => addon.type === 'text');
    },
    configLabels() {
      return this.configurator.reduce((acc, item) => {
        acc[item.name] = item.label;
        return acc;
      }, {});
    },
    priceByPeriod() {
      return this.current.priceByPeriod.map(i => ({
        label: this.$tc(`period.${BillMgrPeriods[i.period]}_c`, i.period),
        value: i.period,
      }));
    },
    periodValue() {
      return this.priceByPeriod.find(i => i.value === this.period);
    },
    balance() {
      return this.$store.getters['moduleProfile/balance'];
    },
    priceListId() {
      return this.current.id;
    },
    currentItem() {
      return this.list.find(i => i.id === +this.id);
    },
  },
  watch: {
    current: function () {
      this.period = 1;
    },
  },
  async mounted() {
    if (!this.current) {
      this.currentItem = this.list.find(i => i.id === +this.id);
      await this.$store.dispatch('moduleColo/moduleColoOrder/setCurrent', +this.id);
    }

    if (!!this.configurator) {
      setTimeout(() => this.setCurrent(this.current), 1000);
      this.fetchPricelist();
    }
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch('moduleColo/moduleColoOrder/reset');
    next();
  },
  methods: {
    // api and logic methods
    fetchPricelist() {
      const params = {
        show_metadata: 'on',
        newface: 'on',
      };
      this.$store.dispatch('moduleColo/moduleColoOrder/fetchPricelist', params);
    },
    fetchCalc(data) {
      return this.$store.dispatch('moduleColo/moduleColoOrder/fetchCalc', data);
    },
    setCurrent() {
      this.$store.dispatch('moduleColo/moduleColoOrder/setCurrent', this.id);
      this.fetchParams(this.id).then(() => this.calcSum(this.current.info.model));
    },
    fetchParams(item, period) {
      const params = {};
      if (item) params.id = item;
      if (period) params.period = period;
      return this.$store.dispatch('moduleColo/moduleColoOrder/fetchParams', params);
    },
    getFormDataParams(payload = {}) {
      const formData = this.configData ? this.configData : this.current.info.model;
      if (
        this.configData &&
        this.configData.autoprolong &&
        this.configData.autoprolong !== 'null'
      ) {
        formData.autoprolong = this.period;
      }
      const params = {
        pricelist: this.current.id,
        ...formData,
        ...payload,
        period: this.period,
      };
      return params;
    },
    calcSum: debounce(function (payload = this.configData) {
      const params = {
        pricelist: this.current.id,
        period: this.period,
      };
      if (payload) Object.assign(params, payload);
      params.period = this.period;
      if (!this.isCalculating) {
        this.isCalculating = true;
        this.fetchCalc(params)
          .then(data => {
            const { cost, cost_details } = data.model;
            this.cost = parseFloat(cost);
            this.costDetails = cost_details;
            this.costDetails.other = this.configTextFields.map(addon => ({
              label: addon.label,
              value: this.configData[addon.name],
            }));
          })
          .catch(e => this.showError(e))
          .finally(() => setTimeout(() => (this.isCalculating = false), 100));
      }
    }, 500),
    sendOrder(payload = {}) {
      const params = this.getFormDataParams(payload);
      return this.$store
        .dispatch('moduleColo/moduleColoOrder/sendOrder', params)
        .catch(e => this.showError(e));
    },
    sendToBasket() {
      this.isSendingToBasket = true;
      this.sendOrder()
        .then(data => {
          this.addToBasketGtm(data, 'Colo');
          const cost = parseFloat(data.itemdesc[0].total_cost);
          this.resetCurrent();
          const text = this.$t('success.basket', { num: this.$n(cost, 'currency') });
          this.$store.dispatch('moduleBasket/fetchBasket');
          this.$store.dispatch('moduleColo/fetchList');
          this.$gtm.trackEvent({
            event: '_event_arrange', // Event type [default = 'interaction'] (Optional)
            category: 'Colo',
            action: 'click',
            label: 'basket',
            value: 5000,
            noninteraction: false, // Optional
          });
          this.showSuccessModal(text);
        })
        .finally(() =>
          setTimeout(() => {
            this.isSendingToBasket = false;
            this.$router.push({ name: 'coloMain' });
          }, 100)
        );
    },
    sendToPay() {
      const payload = {
        not_for_order: 'on',
        skipbasket: '',
        newface: 'on',
        newbasket: 'on',
        clicked_button: 'basket',
        endoflife: 'off',
      };
      const params = this.getFormDataParams(payload);
      this.showResFunc.success = false;
      this.isSendingToPay = true;
      this.$gtm.trackEvent({
        event: '_event_arrange', // Event type [default = 'interaction'] (Optional)
        category: 'Colo',
        action: 'click',
        label: 'pay',
        value: 5000,
        noninteraction: false, // Optional
      });
      this.sendOrder(params)
        .then(data => {
          this.$store.dispatch('moduleBasket/fetchBasket');
          if (data && data.ok && data.ok.type && data.ok.type === 'form' && data.ok.v) {
            const { billorder } = qs.parse(data.ok.v);
            this.startParams = { billorder };
            this.runWizardPay({ notification: true })
              .then(async data => {
                const res = await this.handleRedirect(data.ok);
                this.resetCurrent();
                if (res.ok) {
                  const text =
                    res.func === 'redirect'
                      ? this.$t('success.redirect')
                      : this.$t('success.pay', { num: this.$n(this.cost, 'currency') });
                  this.showSuccessModal(text);
                }
                this.$store.dispatch('moduleColo/fetchList');
                this.updateBalance();
              })
              .catch(() => {
                const basketItem = this.$store.state.moduleBasket.shadow[billorder][0];
                if (basketItem) {
                  this.$store.dispatch('moduleBasket/removeFromBasket', basketItem).finally(() => {
                    this.$store.dispatch('moduleBasket/fetchBasket');
                  });
                }
              });
            // .finally(() =>
            //   setTimeout(() => {
            //     this.isSendingToBasket = false;
            //     this.$router.push({ name: 'mo365Main' });
            //   }, 100)
            // )
          }
        })
        .finally(() => setTimeout(() => (this.isSendingToPay = false), 100));
    },
    // data methods
    updateConfig(data) {
      this.configData = { ...data };
      this.calcSum();
    },
    resetConfig() {
      this.configData = null;
      this.costDetails = null;
    },
    resetCurrent() {
      this.resetConfig();
      this.period = '1';
      this.$store.commit('moduleColo/moduleColoOrder/setCurrent', null);
    },
    onPeriodChange(obj) {
      this.period = obj.value;
      this.calcSum();
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "choise": "Выберите тариф",
    "additional": "Дополнительно",
    "summary": "Итого",
    "payfor": "при оплате за",
    "pay": "Оплатить",
    "order": "В корзину",
    "specs": {
      "disc": "{n} ГБ SSD",
      "mem": "{n} ГБ RAM",
      "ncpu": "{n} CPU"
    },
    "success": {
      "basket": "Заказ на {num} успешно добавлен в корзину",
      "pay": "Хостинг успешно создан, {num} списано с лицевого счета",
      "redirect": "Услуга успешно приобретена, продолжите оплату и дождитесь обновления данных"
    },
    "needbalance": "Необходимо пополнить баланс"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.colo-order {
  &__loading {
    flexy(center, center);
    flex: 1 1 100%;
  }
  &__sum {
    flex: 0 0 100%;

    +breakpoint(ms-and-up) {
      flex: 0 0 auto;
      margin-right: 0.5rem;
    }
  }
  &__price {
    flexy(flex-start, center, wrap);

    &-text {
      margin-right: 0.5rem;

      +breakpoint(sm-and-up) {
        font-size: $font-size-bigger;
      }
    }
  }
  &__period {
    flex: 0 0 8.3rem;

    +breakpoint(sm-and-up) {
      flex: 0 0 10rem;
    }
  }

  &__actions {
    margin: 0.75rem -0.75rem -0.75rem;
    flexy(flex-start, center, wrap);
  }

  &__btn {
    margin: 0.75rem;

    +breakpoint(xs-only) {
      flex: 1 1 100%;
    }
  }
}
</style>
